/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Yi C.",
  title: "Hello, I'm Yi C.",
  subTitle: [
    "A",
    "p",
    "a",
    "s",
    "s",
    "i",
    "o",
    "n",
    "a",
    "t",
    "e",
    "F",
    "u",
    "l",
    "l",
    " ",
    "S",
    "t",
    "a",
    "c",
    "k",
    " ",
    "S",
    "o",
    "f",
    "t",
    "w",
    "a",
    "r",
    "e",
    " ",
    "E",
    "n",
    "g",
    "i",
    "n",
    "e",
    "e",
    "r",
    ".",
    " ",
    "E",
    "x",
    "p",
    "e",
    "r",
    "i",
    "e",
    "n",
    "c",
    "e",
    "d",
    " ",
    "i",
    "n",
    " ",
    "P",
    "r",
    "o",
    "g",
    "r",
    "a",
    "m",
    "m",
    "i",
    "n",
    "g",
    " ",
    "&",
    " ",
    "B",
    "u",
    "i",
    "l",
    "d",
    "i",
    "n",
    "g",
    " ",
    "F",
    "u",
    "l",
    "l",
    " ",
    "S",
    "t",
    "a",
    "c",
    "k",
    " ",
    "A",
    "p",
    "p",
    "l",
    "i",
    "c",
    "a",
    "t",
    "i",
    "o",
    "n",
    "s",
    " ",
    "w",
    "i",
    "t",
    "h",
    " ",
    "J",
    "a",
    "v",
    "a",
    "S",
    "c",
    "r",
    "i",
    "p",
    "t",
    " ",
    "/",
    " ",
    "R",
    "e",
    "a",
    "c",
    "t",
    "j",
    "s",
    " ",
    "/",
    " ",
    "N",
    "e",
    "x",
    "t",
    "j",
    "s",
    " ",
    "/",
    " ",
    "N",
    "o",
    "d",
    "e",
    "j",
    "s",
    " ",
    "/",
    " ",
    "P",
    "y",
    "t",
    "h",
    "o",
    "n",
    " ",
    "/",
    " ",
    "F",
    "l",
    "a",
    "s",
    "k",
    " ",
    "/",
    " ",
    "D",
    "j",
    "a",
    "n",
    "g",
    "o",
    "/",
    " ",
    "P",
    "r",
    "i",
    "s",
    "m",
    "a",
    " ",
    "/",
    " ",
    "P",
    "o",
    "s",
    "t",
    "g",
    "r",
    "e",
    "s",
    " ",
    "/",
    " ",
    "M",
    "y",
    "S",
    "Q",
    "L",
    "."
  ],
  resumeLink: "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/heehyun1128",
  linkedin: "https://www.linkedin.com/in/yi-c-452811132/",
  gmail: "chenyiapp1128@gmail.com",

  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "MY SKILLS",
  subTitle: "",
  skills: [
    emoji(
      "⚡ Passionate About Integrating AI Into Cutting-Edge Software Solutions"
    ),
    emoji(
      "⚡ Develop Highly Interactive Front End / User Interfaces For Your Web Applications"
    ),
    emoji("⚡ Design & Development of Database and API"),
    emoji("⚡ Integration of third party services such as Firebase/ AWS ")
  ],

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
      color: "#F7DF1E"
    },
    {
      skillName: "TypeScript",
      fontAwesomeClassname: "devicon-typescript-original colored"
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python",
      color: "#3776AB"
    },
    {
      skillName: "NextJS",
      fontAwesomeClassname: "devicon-nextjs-original-wordmark colored"
    },
    {
      skillName: "ReactJS",
      fontAwesomeClassname: "fab fa-react",
      color: "#61DAFB"
    },
    {
      skillName: "Redux",
      fontAwesomeClassname: "devicon-redux-original colored"
    },
    {
      skillName: "NodeJS",
      fontAwesomeClassname: "fab fa-node",
      color: "#339933"
    },
    {
      skillName: "ExpressJS",
      fontAwesomeClassname: "fab fa-node-js", // Example class name (adjust as needed)
      color: "orange" // Adjust color as needed
    },
    {
      skillName: "Prisma",
      fontAwesomeClassname: "devicon-prisma-original colored"
    },
    {
      skillName: "Sequelize",
      fontAwesomeClassname: "devicon-sequelize-plain colored"
    },
    {
      skillName: "Flask",
      fontAwesomeClassname: "devicon-flask-original ",
      color: "#16b0c4"
    },
    {
      skillName: "SQLAlchemy",
      fontAwesomeClassname: "devicon-sqlalchemy-plain colored"
    },
    {
      skillName: "Django",
      fontAwesomeClassname: "devicon-django-plain",
      color: "#054d17"
    },
    {
      skillName: "Axios",
      fontAwesomeClassname: "devicon-axios-plain-wordmark colored"
    },
    {
      skillName: "Postgres",
      fontAwesomeClassname: "devicon-postgresql-plain-wordmark colored",
      color: "#336791"
    },
    {
      skillName: "Mysql",
      fontAwesomeClassname: "devicon-mysql-plain-wordmark colored",
      color: "#4479A1"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "devicon-mongodb-plain colored"
    },
    {
      skillName: "Mongoose",
      fontAwesomeClassname: "devicon-mongoose-original-wordmark colored"
    },
    {
      skillName: "Figma",
      fontAwesomeClassname: "devicon-figma-plain colored"
    },
    {
      skillName: "HTML-5",
      fontAwesomeClassname: "fab fa-html5",
      color: "#E34F26"
    },
    {
      skillName: "CSS3",
      fontAwesomeClassname: "fab fa-css3-alt",
      color: "#1572B6"
    },
    {
      skillName: "Grafana",
      fontAwesomeClassname: "devicon-grafana-plain-wordmark colored"
    },

    {
      skillName: "MaterialUI",
      fontAwesomeClassname: "devicon-materialui-plain colored"
    },

    {
      skillName: "TailwindCSS",
      fontAwesomeClassname: "devicon-tailwindcss-plain colored"
    },
    {
      skillName: "Bootstrap",
      fontAwesomeClassname: "devicon-bootstrap-plain colored"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "devicon-java-plain colored"
    },
    {
      skillName: "Visual Basic",
      fontAwesomeClassname: "devicon-visualbasic-plain colored"
    },
    {
      skillName: "JQuery",
      fontAwesomeClassname: "devicon-jquery-plain colored"
    },

    {
      skillName: "Sass",
      fontAwesomeClassname: "fab fa-sass",
      color: "#CC6699"
    },

    {
      skillName: "Redis",
      image:
        "https://img.icons8.com/?size=100&id=pHS3eRpynIRQ&format=png&color=000000"
    },
    {
      skillName: "Postman",
      fontAwesomeClassname: "devicon-postman-plain colored"
    },
    {
      skillName: "Aws",
      fontAwesomeClassname: "fab fa-aws",
      color: "#FF9900"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire",
      color: "#FFCA28"
    },

    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker",
      color: "#2496ED"
    },

    {
      skillName: "VSCode",
      fontAwesomeClassname: "devicon-vscode-plain-wordmark colored"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of California, Berkeley",
      logo: require("./assets/images/uc_berkeley_logo.jpeg"),
      subHeader: "Master of Science - Civil Engineering & Project Management",

      desc: "ACHIEVEMENTS - Berkeley CEE Department Scholarship",
      descBullets: ["Engineering | Project Management | Lean Technologies"],
      image:
        "https://images.unsplash.com/photo-1579748138140-ce9ef2c32db1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
    },

    {
      schoolName: "California Institute of Advanced Management",
      logo: require("./assets/images/ciam.webp"),
      subHeader: "MBA",

      desc: "ACHIEVEMENTS - Four Times on the Dean's List",
      descBullets: ["Finance | Marketing | Communications | Entrepreneurship"]
    },
    {
      schoolName: "Zhengzhou University",
      logo: require("./assets/images/zzu.png"),
      subHeader: "Civil - Water Resources & Hydropower Engineering",

      desc: "ACHIEVEMENTS - Provincial & School Honor Student & Scholarships",
      descBullets: [
        "Computer Basics | Programming with Visual Basic | Linear Algebra | Calculus"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend & API - Node.js / Python / Flask / Django / Prisma",
      progressPercentage: "90%"
    },
    {
      Stack: "Frontend - TypeScript, JavaScript / React.js / Next.js ", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "AI/ML/LLMs",
      progressPercentage: "70%"
    },
    {
      Stack: "Databases - Postgres, MySQL, MongoDB, Firebase",
      progressPercentage: "90%"
    },
    {
      Stack: "UI Design - TailwindCSS / MaterialUI / Framer-motion / Bootstrap", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "OOP Programming",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Canvas",
      companylogo: require("./assets/images/canvas.png"),
      date: "February - June 2024",
      desc: "Next.js 14 | TypeScript | PostgreSQL | Prisma ORM | Zod | Axios | Figma | MaterialUI | FullCalendar | Grafana",
      // descBullets: [
      //   "Led the development and deployment of a comprehensive fleet management software",
      //   "Independently developed performance metrics and Grafana Dashboards to monitor machine operational issues and productivity"
      // ],
      image:
        "https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    },
    {
      role: "Software Developer In Training",
      company: "App Academy",
      companylogo: require("./assets/images/Appacademylogo.png"),
      date: "May - October 2023",
      desc: "JavaScript | React | Redux | Postgres | Python | Flask | Express | AWS",
      // descBullets: [
      //   "ACHIEVEMENTS - 90%+ with zero deferrals | Ranked 1st in Cohort",
      //   "Developed a Full Stack Online social platform for information sharing, discussions, and connections.",
      //   "UI/UX Design in Figma (90%), Tag feature (80%), Search feature (95%) for a Full Stack Online Retail Platform Selling Pet Products."
      // ],
      image:
        "https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    },
    {
      role: "Software Developer ",
      company: "Self-performed/Freelance",
      companylogo: require("./assets/images/freelance.png"),
      date: "June 2022 – presenr",
      desc: "Python3 | JavaScript ES5/ES6+ | MERN Stack | RESTful API | SQL/NoSQL Databases |  jQuery | Ajax | Axios | HTML | CSS | PHP | OOP | GUI | Pandas",
      // descBullets: [
      //   "Developed a Role-based Organization Management App using MERN Stack.",
      //   "Built the frontend/UI for a Online Designer Fashion Store"
      // ],
      image:
        "https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    },
    {
      role: "Project Engineer",
      company: "Giampolini",
      companylogo: require("./assets/images/giampolini.png"),
      date: "October 2017 – December 2020",
      desc: "Visual Basic for Application | Miscrosoft Access | Project Management",
      // descBullets: [
      //   "Independently designed and developed a Purchase Order Generation and Tracking System using VBA (Microsoft Visual Basic), enforcing data collection, validation, analyzation, and visualization.",
      //   "Project Management & Planning"
      // ],
      image:
        "https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    },
    {
      role: "Project Engineer",
      company: "Innovative Project Solutions",
      companylogo: require("./assets/images/ips.jpeg"),
      date: "March 2021 – November 2021",
      desc: "Project QA/QC | Project Management, Planning & Scheduling",
      // descBullets: ["Led the QA/QC and cost estimation of project documents"],
      image:
        "https://images.unsplash.com/photo-1579310962131-aa21f240d986?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "PROJECTS",

  subtitle: "Full Stack & AI Applications",
  projects: [
    {
      image: require("./assets/images/moment.gif"),
      projectIntro: `Moment is a full-stack online social platform where users connect and communicate with each other.##
        Built with React and Redux on the frontend and Flask with SQLAlchemy on the backend, it supports core social features such as post creation, commenting, liking, following, and profile management.##
       User authentication is custom-built and ensures secure access, while AWS S3 handles file storage.##
        The platform leverages OpenAI for AI-driven content generation, enhancing user engagement. PostgreSQL provides a reliable database solution, and the architecture is designed for scalability and performance.##
         `,
      type: "ai & full-stack",
      projectName: "Moment",
      projectDesc:
        "Full-stack Online social platform with AI content generation",
      features:
        "Posts, Comments, Search, Liking, Following, Profile Management, User Auth, AI Content Generation, AWS File Management",
      stack:
        "React | Redux | Python | JavaScript | Flask | SQLAlchemy | AWS S3 | PostgreSQL | OpenAI | CSS",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://moment-2igd.onrender.com/"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyunyc0710/Moment_Social_Media_Platform"
        // }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/positioning.png"),
      projectIntro: `Positioning AI is a platform designed to support computer science students in their job search by using the power of AI. The idea came from my own experience using AI search engines to learn technical concepts and improve my job search.##
       This platform allows users to ask questions (with file attachments) in natural human language and receive accurate and relevant AI responses, together with source citations, through a RAG pipeline.##
       Caching is implemented to improve response speed, and rate limiting is used to prevent abuse.##
       Docker is used for containerization and deployment.`,
      type: "ai & full-stack",
      projectName: "Positioning AI - AI Job Search Assistant",
      projectDesc:
        "A platform enabling semantic search and AI responses with source citations, allowing efficient session management.",
      features:
        "Chat (AI RAG), Session, Source Citation, File Attachment, Caching, Rate Limiting",
      stack:
        "Next.js | TypeScript | Python | Flask | MongoDB | OpenAI | Pinecone | Upstash | Redis | Docker | Tailwind CSS | Framer Motion | Shadcn",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/3Y6Lqq2eT_s"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyunyc0710/Moment_Social_Media_Platform"
        // }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/llm.png"),
      projectIntro: `This project is  a platform for evaluating large language models (LLMs) using weather data. This platform allows you to select from multiple LLMs—such as GPT-4, LLaMA-3, and Gemma-2—and test their accuracy and performance.##

You begin by asking a question about the dataset and providing the expected response. The platform then generates answers from each selected model, enabling direct comparison. Additionally, it produces an overall model comparison analysis and evaluates each model's accuracy based on its responses.##

By leveraging this evaluation engine, you can gain valuable insights into how different LLMs handle weather-related queries, helping to identify their strengths and weaknesses..`,

      type: "ai & full-stack",
      projectName: "LLM Evaluation",
      projectDesc: "A platform for LLM performance evaluation",
      features:
        "Evaluate LLMs, Estimate LLM Accuracy, Data Management (Data Sorting/Filtering), Semantic Search",
      stack:
        "Next.js | TypeScript | Python | Flask | MongoDB | LLMs | Pinecone | Tailwind CSS | Framer Motion",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/erCBvPESuxI"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyunyc0710/Moment_Social_Media_Platform"
        // }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/inventa.gif"),
      projectIntro: `Inventa is a full-stack AI-powered inventory management system that allows users to take pictures of product labels and update inventory instantly.##
       Product labels are parsed using a vision model, and the extracted text is used to update the inventory.##
       The system also includes a data visualization feature to help users track their inventory levels.`,
      type: "ai & full-stack",
      projectName: "Inventa - AI Inventory Management",
      projectDesc:
        "Full-stack AI-powered Inventory Management System that allows users to take pictures of product labels and update inventory instantly (Camera/Vision Model)",
      features:
        "Image Capturing & Upload, Character Recognition, Inventory Update & Management, Data Visualization",
      stack:
        "NextJS | Firebase | TypeScript | OPENAI GPT Vision | Vercel CI/CD | React Camera Pro | Material UI",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://inventa-v8of.vercel.app/"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Inventa"
        // }
      ]
    },
    {
      image: require("./assets/images/yuzu.png"),
      projectIntro: `Yuzu is a text-to-image platform that allows users to generate images with text prompts. The AI image generation model is hosted on serverless GPUs (Modal) and ensure low latency for a smooth user experience.##
       Users can generate images with text prompts, select image configurations and styles, view image generation history, and view chat history.`,
      type: "ai & full-stack",
      projectName: "Yuzu - Realtime Image Diffusion",
      projectDesc:
        "AI text-to-image platform. Users can generate images with text prompts. The AI image generation model is hosted on serverless GPUs (Modal) and ensure low latency for a smooth user experience.",
      features:
        "Chat for Text-To-Image Generation, View Image Generation History, View Chat History, User Authentication w/ Clerk",
      stack:
        "Modal, StableDiffusion, Python, Next.js, TypeScript, and Tailwind CSS.",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://youtu.be/jfkgVSRUk9s"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Yuzu-AI-text-to-image"
        // }
      ]
    },
    {
      image: require("./assets/images/echo.gif"),
      projectIntro: `Echo is AI-powered project management tool that enables users to interact with project documents through conversations. Users can record their questions about the document, and then the speech will be converted to text by AI.##
       The system processes the user requests, retrieves relevant information from the database, and responds by converting the text-based answer back into speech. This allows users to easily access project details through a conversational and hands-free experience. 
       `,
      type: "ai & full-stack",
      projectName: "Echo - AI File Manager Through Chats",
      projectDesc:
        "AI-powered Construction Document Management platform that allows users manage project files through conversations (speaking/listening). Currently under development",
      features:
        "AI Responses in Audio & Text Format: Text-to-Speech, Voice recording & Processing: Speech-To-Text, Semantic Search (RAG), AWS File Management",
      stack:
        "Natural Language Processing | NextJS | TypeScript | Python | Fast API | AWS | Hugging Face | Pinecone | Google Gemini",
      footerLink: [
        {
          name: "View Website",
          url: "https://www.project-echo.us/"
        },
        {
          name: "View Demo",
          url: "https://www.linkedin.com/posts/activity-7247513369560154113-09zo?utm_source=share&utm_medium=member_desktop"
        }
      ]
    },
    // Codebase RAG
    {
      image: require("./assets/images/codebase-rag.png"),
      projectIntro: `Compass is an AI-powered system that incorporates RAG on Github codebases to generate relevant and accurate answers about the codebase.##
       The system allows users to submit a codebase url and answer questions based on user queries and the provided codebase.##
       The system uses a combination of Langchain, HuggingFaceEmbeddings, OpenAI, Pinecone, and Flask to provide a semantic search on the codebase.`,
      type: "ai & full-stack",
      projectName: "Compass - Codebase RAG",
      projectDesc:
        "This AI system allows Users to submit a codebase url and answer questions based on user queries and the provided codebase.",
      features: "RAG on Github Codebase That Allows Semantic Search",
      stack:
        "Python, Flask, Next.js, TypeScipt, Framer-motion, Langchain, HuggingFaceEmbeddings, OpenAI, Pinecone",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://youtu.be/XxZWdxxD1Jg"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Compass-codebase-rag"
        // }
      ]
    },
    {
      image: require("./assets/images/wenti.png"),
      projectIntro: `Wenti is an AI-powered system designed for dynamic web scraping and semantic search. It enables users to submit a webpage URL and retrieve relevant answers to their queries based on the content of the provided webpage.##
       The architecture leverages Puppeteer for headless browser automation, allowing for efficient data extraction from complex web pages.##
       Redis is utilized for caching responses, enhancing performance and reducing latency.##
       The system integrates OpenAI's language models to process user queries and generate contextually relevant answers, ensuring high accuracy and relevance in the responses.##
      `,
      type: "ai & full-stack",
      projectName: "Wenti - AI Answer Engine",
      projectDesc:
        "This AI system scrapes linked webpage using puppeteer and get instant response to user queries with Redis caching and rate limiting.",
      features:
        "Web Scraping with Puppeteer, AI Response Generation w/ RAG, Chat History Management",
      stack:
        "Next.js | TypesScript | Redis | Upstash | Groq | Puppeteer | Tailwind CSS",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://youtu.be/syUL5fO-iVs"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/wenti-AI-Answer-Engine"
        // }
      ]
    },
    {
      image: require("./assets/images/finance.png"),
      type: "ai & full-stack",
      projectIntro: `This project is a financial analysis automation system that allows users to ask questions about stocks in natural human language and receive accurate and relevant AI responses.##
       The system uses a combination of Python, Flask, Pinecone, and OpenAI API to provide a semantic search on the stocks.`,
      projectName: "Financial-Analysis-Automation-w/LLMs",
      projectDesc:
        "A system that can find relevant stocks based on natural language queries from the user",
      features:
        "Ask Questions About Stocks in Natural Human Language, AI Response Generation (RAG)",
      stack:
        "Next.js | TypesScript | Python | Flask | Pinecone | OpenAI api | Tailwind CSS",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/XlVR7L1OiNY"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyunyc0710/Financial-Analysis-Automation-with-LLMs",
        // },
      ]
    },
    // code review agent
    {
      image: require("./assets/images/pr-agent.png"),
      projectIntro: `This project is an AI-powered code review agent that allows users to review code changes in Pull Requests and provide feedback on the code quality and style and suggest code changes and improvements.##
       The system uses a combination of Python, JavaScript, Ngrok, Github API, Webhook, and Groq to provide a semantic search on the codebase.`,
      type: "ai & full-stack",
      projectName: "AI PR Review Agent",
      projectDesc:
        "This AI system reviews code changes in Pull Requests and provides feedback on the code quality and style.",
      features:
        "Automatically Reviewing Pull Requests When Submitted, Generate AI Feedback about PR",
      stack: "Python | JavaScript | Ngrok | Github API | Webhook | Groq",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://youtu.be/KQKzfZwHDJw"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/AI-Code-Review-Agent"
        // }
      ]
    },
    // customer churn prediction
    {
      image: require("./assets/images/churn_predict.png"),
      projectIntro: `This project is a customer churn prediction system that allows users to predict customer churn for a bank.##
       The system uses a combination of Pandas, Scikit-learn, Logistic Regression, XGBoost, Decision Tree, Random Forest, Naive Bayes, K-Nearest Neighbors, and Support Vector Machine
       for model training and the frontend is built with Streamlit for the user to visualize the customer churn prediction.`,
      type: "ml",
      projectName: "Customer Churn Prediction",
      projectDesc:
        "This project trained machine learning models to predict customer churn for a bank. The process focused on model inference, and using feature engineering, SMOTE, and Ensembling to improve prediction accuracy for identifying churners.",
      features:
        "Streamlit Frontend: Visualize Customer Churn Possibility, Model Training on Multiple ML Models",
      stack:
        "Python | Pandas | Scikit-learn | Logistic Regression | XGBoost | Decision Tree | Random Forest | Naive Bayes | K-Nearest Neighbors | Support Vector Machine, Streamlit",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://www.youtube.com/watch?v=TuclSbltiPI"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/customer_churn_prediction"
        // }
      ]
    },
    // Brain Tumor Classification
    {
      image: require("./assets/images/brain.png"),
      projectIntro: `This project is a brain tumor classification system that allows users to classify brain tumors.##
       The system uses a combination of Pandas, Numpy, Matplotlib, Scikit-learn, TensorFlow, and Keras for model training and the frontend is built with Streamlit for the user to visualize the brain tumor classification.`,
      type: "ml",
      projectName: "Brain Tumor Classification",
      projectDesc:
        "This project trained machine learning models to classify brain tumors.",
      features:
        "Streamlit Frontend: Visualize Brain Imaging Records, Model Training on Multiple ML Models",
      stack:
        "Python | Pandas |  Numpy | Matplotlib | Seaborn | Scikit-learn | TensorFlow | Keras | Convolutional Neural Networks (CNNs)",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://youtu.be/VNKw84sPGpY"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Brain-Tumor-Classification"
        // }
      ]
    },
    {
      image: require("./assets/images/garry.gif"),
      projectIntro: `This project is an AI-powered "Rate My Professor" platform that enables users to recommend professors based on their experiences and specific requests.##
       The architecture uses Pinecone for efficient vector storage and retrieval, allowing for fast semantic search capabilities across professor ratings.##
        OpenAI's language models are integrated to analyze user queries and generate contextually relevant recommendations.
        ##Playwright is utilized for automated web scraping, enabling the collection of real-time data from various educational platforms to keep the ratings up-to-date.##
     `,
      type: "ai & full-stack",
      projectName: "Dream Professor",
      projectDesc: "AI-powered Rate My Professor Platform",
      features:
        "Recommend Professors w/ AI per User Requests (RAG), Web Scraping on User Submitted Professor Rating Links, Automatically Update Database with Scraped Data",
      stack:
        "NextJS | TypeScript | Pinecone | OpenAI | Sentiment Analysis | Playwright Web Scraping ",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/OlWGVpPpbb4"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/dream_prof"
        // }
      ]
    },
    {
      image: require("./assets/images/cardia.gif"),
      projectIntro: `This project is a full-stack SaaS AI-powered flashcard creation platform that allows users to create, manage, and study flashcards based on user-submitted topics.##
       Users can input specific subjects or concepts, and the system utilizes OpenAI's language models to generate relevant flashcard content, including questions and answers.##
      Authentication and user management are handled by Clerk. Stripe is integrated for payment processing.##
      Firebase serves as the backend database, providing real-time data synchronization and storage for user-generated content. `,
      type: "ai & full-stack",
      projectName: "Cardia AI",
      projectDesc: "Full-stack SaaS AI-powered Flashcard Creation Platform",
      features:
        "AI-generated Flaskcards based on User Submitted Topics, Flashcard Generation History, Stripe Paywall, User Auth w/ Clerk",
      stack: "NextJS | TypeScript | Clerk | Stripe | OpenAI | Firebase",
      footerLink: [
        {
          name: "View Demo",
          url: "https://www.youtube.com/watch?v=Fv0U_stcL0o"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/flashcard"
        // }
      ]
    },
    {
      image: require("./assets/images/rulla.gif"),
      projectIntro: `This project is a full-stack AI-powered sales transcript note-taking and file management system that enables users to efficiently take notes on sales transcripts.##
       The system utilizes OpenAI's language models to analyze the content, extract key insights, and generate summaries of the transcript.##

      The architecture leverages AWS services for scalability and reliability:##
      - AWS DynamoDB serves as the NoSQL database for storing user notes 
      ##- AWS Lambda handles serverless computing tasks. AWS API Gateway facilitates secure and scalable API management.

      ##- AWS S3 is utilized for file storage, allowing users to upload, retrieve, and manage their sales transcripts securely.`,
      type: "ai & full-stack",
      projectName: "Rulla",
      projectDesc:
        "Full-stack AI-powered sales transcript note-taking and file management system",
      features:
        "Note Taking on Sales Transcripts, AI-generated Summary of Transcript Content & Comments",
      stack:
        "AWS DynamoDB | AWS Lambda | AWS API Gateway | AWS S3 | NextJS | OpenAI",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/M1iIi9_NeAw"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/rulla"
        // }
      ]
    },
    {
      image: require("./assets/images/petsy.gif"),
      projectIntro: `This project is a full-stack E-Commerce platform that allows users to search for products, sell products, and manage their products.##
      Key features include user authentication for secure access and the ability to browse and filter product listings by categories and tags. 
       Users can create, edit, and delete their product listings, as well as view detailed product information and associated reviews. 
       ##The platform also supports a favorites feature, allowing users to save products for easy access later. 
       Additionally, users can leave reviews and ratings for products, enhancing community engagement and trust. 
     `,
     type: "full-stack",
      projectName: "Petsy",
      projectDesc: "Full-stack E-Commerce Platform.",
      features:
        "Search, Product Listing/Selling, Product Review, Product Favorate, Product Tags, User Auth, Shopping Cart/Checkout",
      stack:
        "React | Redux | Python | JavaScript | Flask | SQLAlchemy | CSS | PostgreSQL | Figma",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://www.youtube.com/watch?v=zZzEIE8DafE"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/nickhosman/Petsy"
        // }
      ]
    },

    {
      image: require("./assets/images/gogoal.gif"),
      projectIntro: `This project is a full-stack AI-powered goal management app that enables users to create, track, and manage their personal and professional goals effectively.##
       Users can set specific, measurable, achievable, relevant, and time-bound (SMART) goals, and the app provides personalized motivational messages and reminders to help them stay on track.##

       The frontend is built with Next.js and Tailwind CSS, ensuring a responsive and visually appealing user interface.

      MongoDB is used for storing user data, goals, and progress tracking, while Python and Flask are used for the backend API. Vercel CI/CD is employed for seamless deployment and continuous integration. 
      `,
      type: "ai & full-stack",
      projectName: "Go Goal",
      projectDesc: "Full-stack AI-powered Goal Management App",
      features:
        "Create and Manage Goals, AI-generated Due-Today Goal List, AI-generated Motivational Messages",
      stack:
        "OpenAI | NextJS | JavaScript | MongoDB | Python | Flask | Vercel CI/CD | Tailwind CSS",
      footerLink: [
        {
          name: "View Demo",
          url: "https://youtu.be/pCFSJ4_Weyk"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/goal_tracker"
        // }
      ]
    },

    {
      image: require("./assets/images/thinkey.gif"),
      projectIntro: `This project is a full-stack AI chat-image-video platform that enables users to generate images, videos, and audio content using natural human language.##
       The system utilizes OpenAI's language models to analyze user queries and generate contextually relevant content.##
       The frontend is built with Next.js and Tailwind CSS, ensuring a responsive and visually appealing user interface.
      `,
      type: "ai & full-stack",
      projectName: "Thinkey",
      projectDesc: "Full-stack AI Chat-Image-Video Platform",
      stack: "NextJS | TypeScript | OpenAI API | Clerk | Tailwind CSS",
      features:
        "AI Video Generation,  AI Audio Generatio, AI Image Generation, AI Text Content Generation ",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://thinkey-cvjyau33x-heehyun1128s-projects.vercel.app/"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Thinkey"
        // }
      ]
    },
    {
      image: require("./assets/images/picto.gif"),
      projectIntro: `This project is a full-stack SaaS AI photo-processing application that enables users to process images with the help of AI.##
       The system utilizes OpenAI's language models to analyze user queries and perform image processing tasks such as background removal and magic fill.##
      User authentication is handled by Clerk. Stripe is integrated for payment processing. Cloudinary is used for image storage and retrieval.##
      `,
      type: "ai & full-stack",
      projectName: "Picto",
      projectDesc: "Full-stack Saas AI Photo-Processing Application",
      features:
        "AI Image processing (e.g. Background Removal | Magic Fill), Clerk User Auth, Stripe Paywall",
      stack:
        "NextJS | TypeScript | MongoDB | Mongoose | Stripe | Clerk | CloudinaryAI | OpenAI",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://picto-silk.vercel.app/"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Picto"
        // }
      ]
    },
    // Real-time chat application
    {
      image: require("./assets/images/chat-app.png"),
      projectIntro: `This project is a full-stack real-time chat application that enables users to chat with each other in real-time.##
       The system utilizes Convex for database management.##
       Clerk is used for user authentication and webhooks are used for connecting Clerk user information with Convex.##
       
      `,
      type: "full-stack",
      projectName: "Real-time Chat App  ",
      projectDesc: "This is a full-stack real-time chat application",
      features: "Clerk Auth w/ Webhooks, Messenging, File Attachment",
      stack:
        "Convex | Nextjs | TypeScript | Clerk | Webhook | Shadcn | Tailwind CSS",
      footerLink: [
        {
          name: "Visit Demo",
          url: "https://youtu.be/Ez3NxBUxxwc"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Convo-cord"
        // }
      ]
    },

    {
      image: require("./assets/images/chatter.gif"),
      projectIntro: `This project is a full-stack AI-powered chat assistant that enables users to ask questions in natural human language and generate AI responses in multiple languages.##
       The system utilizes Pinecone for vector storage and retrieval.
       LLMs - Claude, LLama, OpenAI are used for AI response generation.##
       AWS Bedrock is used for AI model inference.
       AWS EC2 is used for server deployment.
      `,
      type: "ai & full-stack",
      projectName: "Chatter AI",
      projectDesc: "Full-stack AI-powered Chat Assistant",
      features:
        "Ask Questions in Natural Human Language, AI Response w/ Milti-Language Support",
      stack:
        "Retrieval-Augmented Generation (RAG) | Pinecone | LLMs - Claude, LLama, OpenAI | AWS Bedrock | AWS EC2 | NextJS | TypeScript | Python | Flask",
      footerLink: [
        {
          name: "View Demo",
          url: "https://www.youtube.com/watch?v=jKylYEVI1sM"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/Chatters"
        // }
      ]
    },
    // {
    //   image: require("./assets/images/drone.gif"),
    //   type:"ai",
    //   projectName: "Drone Flight Data Management",
    //   projectDesc:
    //     "This software allows users to ask questions about images captured during drone flights by using natural human language.",
    //   stack: "Python, Flask, Next.js, TypeScript, OpenAI vector embeddings, Pinecone,  Docker,  Framer-motion, Tailwind CSS.",
    //   footerLink: [

    //     {
    //       name: "Visit Demo",
    //       url: "https://youtu.be/84zE4b4tqiY"
    //     },
    //     // {
    //     //   name: "Visit Github",
    //     //   url: "https://github.com/heehyun1128/Yuzu-AI-text-to-image"
    //     // }
    //   ]
    // },

    {
      image: require("./assets/images/suju.png"),
      projectIntro: `This project is a UI design/frontend-only application built using Vanilla JavaScript, Express, HTML, and CSS for an online designer fashion store that enables users to browse and purchase designer fashion products.##
      The UI showcases functional components such as a navigation bar, product listing, product details, favorites, and a shopping cart.##
      MailChimp API is used for email marketing.##
      `,
      type: "ui / frontend",
      projectName: "SUJU",
      projectDesc: "Frontend Design for an Online Designer Fashion Store",
      features: "UI Design for an E-commerce store",
      stack:
        "JavaScript DOM | Express | HTML | CSS | Bootstrap | MailChimp API",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://suju-fashion-9bfee9186406.herokuapp.com/"
        }
      ]
    },
    {
      image: require("./assets/images/meetfriends.gif"),
      projectIntro: `This project is a full-stack MeetUp clone project that enables users to create and manage social groups and events.##
       The system utilizes Sequelize ORM, Node.js, Express, and PostgreSQL for the backend.##
       The frontend is built with React, Redux, and Node.js.##
      `,
      type: "full-stack",
      projectName: "MeetFriends",
      projectDesc: "A Full-stack MeetUp Clone Project",
      features:
        "Create and Manage Social Groups, Create and Manage Events, User Auth",
      stack:
        "React | Redux/Redux-thunk | HTML 5 | CSS | Javascript | Nodejs/Express |Sequelize ORM | PostgreSQL",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://meetup-backend-witc.onrender.com/"
        }
        // {
        //   name: "Visit Github",
        //   url: "https://github.com/heehyun1128/MeetFriends"
        // }
      ]
    },
    {
      image: require("./assets/images/meedyia.gif"),
      projectIntro: `This project is a full-stack video streaming website that enables users to find videos by categories and view videos.##
       The system utilizes RapidAPI for video streaming.##
       The frontend is built with React, JavaScript, and Material UI.##
      `,
      type: "full-stack",
      projectName: "Meedyia",
      projectDesc: "A Video Streaming Website",
      stack: "React | JavaScript | RapidAPI | Material UI",
      features: "Find Videos By Categories, View Videos",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://fascinating-halva-6b93a2.netlify.app/"
        }
      ]
    }
    // {
    //   image: require("./assets/images/snake.gif"),
    //   projectName: "Snake Game",
    //   projectDesc: "",
    //   stack:"Python | Turtle Graphics",
    //   footerLink: [

    //     {
    //       name: "Visit Github",
    //       url: "https://github.com/heehyun1128/Snake-Game"
    //     },
    //   ]
    // },
    // {
    //   image: require("./assets/images/littlelemon.png"),
    //   projectName: "Little Lemon Restraurant",
    //   projectDesc: "",
    //   stack:"Python3, Django DRF, MySQL, React, CSS, JavaScript, Djoser, Insomnia",
    //   footerLink: [

    //     {
    //       name: "Visit Github",
    //       url: "https://github.com/heehyun1128/littlelemon_cap"
    //     },
    //   ]
    // },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("CERTIFCATIONS"),
  subtitle:
    "Professional Certifications in Software Engineering & Agile Project Management",

  achievementsCards: [
    {
      title: "Meta Back-End Developer Professional Certificate",
      subtitle:
        "Databases for Back-End Development | APIs | Django | DjangoDRF | Python | Version Control",
      image: require("./assets/images/meta.png"),
      imageAlt: "Meta Back-End Developer Professional Certificate",
      footerLink: [
        {
          name: "View Meta Back-End Developer Certificate",
          url: "https://coursera.org/share/5e95e76bbcabbe4ef2338937d88ea487"
        }
      ]
    },
    {
      title: "Meta Front-End Developer Professional Certificate",
      subtitle: "React | HTML | CSS | JavaScript | UX/UI Design ",
      image: require("./assets/images/meta.png"),
      imageAlt: "Meta Front-End Developer Professional Certificate",
      footerLink: [
        {
          name: "View Meta Front-End Developer Certificate",
          url: "https://coursera.org/share/64f8e1438e4e621d85372a571dc77811"
        }
      ]
    },
    {
      title: "Object-Oriented Data Structures in C++",
      subtitle: "C++ Class, Memory Model, Software Solutions.",
      image: require("./assets/images/UIUC.png"),
      imageAlt: "UIUC Logo",
      footerLink: [
        {
          name: "View Certificate",
          url: "https://coursera.org/share/c2581c70a394d30ae08f31242393b3f0"
        }
      ]
    },

    {
      title: "Project Management Professional (PMP)®",
      subtitle: "Agile Project Management",
      image: require("./assets/images/PMP.png"),
      imageAlt: "PMP Logo",
      footerLink: [
        {
          name: "View PMP Certification",
          url: "https://drive.google.com/file/d/1VcYRpFWPoIuSakdcxnPleeTSzJH1U05N/view?usp=sharing"
        }
      ]
    },
    {
      title: "The complete 2023 Web Development Bootcamp",
      subtitle:
        "MongoDB, Mongoose, ExpressJS, React, JavaScript/NodeJS, jQuery, Bootstrap, HTML, CSS",
      image: require("./assets/images/udemy.jpg"),
      imageAlt: "Udemy Certificate Logo",
      footerLink: [
        {
          name: "View Certification",
          url: "https://www.udemy.com/certificate/UC-fc8dcda5-275d-4dc2-bd9a-0a9da741e03e/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "AWARDS",
  subtitle: "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Berkeley CEE Department Scholarship"
      // description: "2016"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School's Honor Student"
      // description: "2015"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Second Class School Scholarship"
      // description: "2015"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Provincial Honor Student with Excellent Performance",
      description: "2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "First Class School Scholarship",
      description: "2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School's Honor Student",
      description: "2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title:
        "Third Class Award of National English Competition for College Students",
      description: "2014"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School's Honor Student",
      description: "2013"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Second Class School Scholarship",
      description: "2013"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Excellent Prize in School's Singing Contest",
      description: "2012"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "School's Excellent Student ",
      description: "2012"
    },
    {
      url: "https://www.linkedin.com/in/yi-c-452811132/details/honors/",
      title: "Third Class School Scholarship",
      description: "2012"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: "CONTACT ME",
  subtitle:
    "Discuss a job opportunity or a project, or just connect with me :)",
  linkedin: "https://www.linkedin.com/in/yi-c-452811132/"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
