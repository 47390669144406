import React, {useContext, useState, useEffect} from "react";
import "./StartupProjects.scss";
import {bigProjects} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import {useNavigate} from "react-router-dom";

export default function StartupProject() {
  const navigate = useNavigate();
  const [allProjects, setAllProjects] = useState([]);
  const [showProjectIntro, setShowProjectIntro] = useState({});
  useEffect(() => {
    setAllProjects(bigProjects.projects);
  }, []);

  function openUrlInNewTab(url) {
    if (!url) {
      return;
    }
    var win = window.open(url, "_blank");
    win.focus();
  }

  const {isDark} = useContext(StyleContext);
  if (!bigProjects.display) {
    return null;
  }

  return (
    <div className="project-card">
      <div className="main" id="projects">
        <div>
          <div style={{display: "flex", alignItems: "center"}}>
            <h1 className="skills-heading">{bigProjects.title}</h1>

            <h4 className="skills-subheading">{`(${bigProjects.projects.length})`}</h4>
          </div>
          <p
            className={
              isDark
                ? "dark-mode project-subtitle"
                : "subTitle project-subtitle"
            }
          >
            {bigProjects.subtitle}
          </p>
          <div className="projects-container">
            {allProjects.map((project, i) => {
              return (
                <>
                  {showProjectIntro[project.projectName] && (
                    <>
                      <div
                        className={
                          isDark
                            ? "dark-mode project-card project-card-dark"
                            : "project-card project-card-light"
                        }
                      >
                       <div style={{display: "flex", flexDirection: "column", alignItems: "space-between", height: "100%"}}>

                       <h2>Project Introduction</h2>
                       <div>
                       <p className="card-subtitle">
                          {project.projectIntro
                            .split("##")
                            .map((paragraph, index) => (
                              <p key={index}>{paragraph}</p>
                            ))}
                        </p>
                       </div>
                       </div>
                        <div className="project-card-footer">
                          <span
                            key={i}
                            className={
                              isDark ? "dark-mode project-tag" : "project-tag"
                            }
                            onClick={() =>
                              setShowProjectIntro(prev => ({
                                ...prev,
                                [project.projectName]: false
                              }))
                            }
                          >
                            Close
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  {!showProjectIntro[project.projectName] && (
                    <div
                      onClick={() =>
                        navigate(`${project.projectName.toLowerCase()}`)
                      }
                      key={i}
                      className={
                        isDark
                          ? "dark-mode project-card project-card-dark"
                          : "project-card project-card-light"
                      }
                    >
                      {project.image ? (
                        <div className="project-image">
                          <img
                            src={project.image}
                            alt={project.projectName}
                            className="card-image"
                          ></img>
                        </div>
                      ) : null}
                      <div className="project-detail">
                        <p
                          style={{
                            padding: "4px 2px 0px 6px",
                            margin: "0px",
                            fontSize: "12px",
                            color: "red",
                            textAlign: "end"
                          }}
                        >
                          {project.type.toUpperCase()}
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center"
                          }}
                        >
                          <h5
                            className={
                              isDark ? "dark-mode card-title" : "card-title"
                            }
                          >
                            {project.projectName}
                          </h5>
                        </div>
                        <p
                          className={
                            isDark ? "dark-mode card-subtitle" : "card-subtitle"
                          }
                        >
                          {project.projectDesc}
                        </p>
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "flex-start",
                            justifyContent: "flex-start",
                            padding: "0px",
                            paddingLeft: "10px"
                          }}
                        >
                          {project.features
                            ?.split(", ")
                            .map((feature, index) => (
                              <li
                                style={{
                                  fontSize: "12px",
                                  color: "gray",
                                  textAlign: "start"
                                }}
                                key={index}
                              >
                                {feature}
                              </li>
                            ))}
                        </ul>
                        <p style={{fontSize: "14px", color: "#4682A9"}}>
                          {project.stack}
                        </p>
                        {project.footerLink ? (
                          <div className="project-card-footer">
                            {project.footerLink.map((link, i) => {
                              return (
                                <span
                                  key={i}
                                  className={
                                    isDark
                                      ? "dark-mode project-tag"
                                      : "project-tag"
                                  }
                                  onClick={() => openUrlInNewTab(link.url)}
                                >
                                  {link.name}
                                </span>
                              );
                            })}
                            <span
                              key={i}
                              className={
                                isDark ? "dark-mode project-tag" : "project-tag"
                              }
                              onClick={() =>
                                setShowProjectIntro(prev => ({
                                  ...prev,
                                  [project.projectName]: true
                                }))
                              }
                            >
                              Project Intro
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
